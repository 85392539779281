:root {
  --primary: #2e304b;
  --primary: #6c584c;
  /* --primary: #124559; */
  --primary: #3c6e71;
  /* --primary: #eae0d5; */
  /* --primary: #0a0908; */
  --secondary: #fd8e8e;
  --secondary: #f0ead2;
  --secondary: #dddad8;
  /* --secondary: #0a0908; */
  /* --secondary: #eae0d5; */
  --third: #f1f1f1;
  --black: #2e304b;
  --grey: #6b6c7b;
}

.main-container {
  display: flex;
}

.info-wrapper {
  flex: 1;
  position: -webkit-sticky;
  align-self: flex-start;
  position: sticky;
  top: 0;
  background-color: var(--primary);
  color: var(--secondary);
  height: 100vh;
  width: 100%;
}

.info {
  padding-inline: 5rem;
  /* padding-inline: 10%; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.info-header {
  font-size: 3rem;
  font-weight: 700;
  margin-block: 0rem;
  margin-top: auto;
}

#check{
  /* margin:2px auto; */
  width: fit-content;
}

#typewriter {
  border-right: 1px solid;
}

.info-summary {
  width: 70%;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.info-links {
  color: var(--third);
  margin-top: auto;
  margin-bottom: 3rem;
  font-size: 1rem;
}

.info-socials {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.social-icon {
  fill: var(--third);
  margin-left: 0.5rem;
}

.social-icon:hover {
  fill: var(--secondary);
  cursor: pointer;
}

.info-resume-link {
  color: var(--third);
}

.info-resume-link:hover {
  color: var(--secondary);
  cursor: pointer;
}

.lets-connect {
  margin-top: 2rem;
  width: 70%;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  border: var(--secondary) 1px solid;
  border-radius: 2rem;
  width: fit-content;
  padding: 1rem 3rem;
  transition: all 0.3s ease-in-out;
}

.lets-connect:hover {
  background-color: var(--secondary);
  color: var(--primary);
  cursor: pointer;
}

.portfolio-details {
  background-color: var(--primary);
  flex: 1;
}

.portfolio-section {
  height: 100vh;
}

.contact-section {
  min-height: 100vh;
}

.sticky-section {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.portfolio-image-box{
  overflow: hidden;
}

.portfolio-image {
  width: 100%;
}

.portfolio-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--secondary);
  opacity: 0.5;
  width: 100%;
  height: 100%;
}

.about-me-wrapper {
  background-color: white;
}

.about-me {
  color: var(--black);
  padding-inline: 10%;
  /* height: 100%; */
  padding-top: 5%;
}

.about-me-header {
  font-size: 2rem;
  font-weight: 600;
  margin-block: 0rem;
}

.about-me-summary {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.about-me-sub-header {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.about-me-paragraph {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: var(--grey);
}

.about-me-namaste {
  margin-top: 2rem;
  color: var(--grey);
  font-family: 'Courier New', Courier, monospace;
}

.about-me-namaste-translation {
  font-family: 'Poppins', sans-serif;
}

.skills-wrapper {
  background-color: white;
  /* color: var(--secondary); */
}

.skills {
  display: flex;
  columns: 2;
  flex-wrap: wrap;
  height: 100%;
}

.skill {
  box-sizing: border-box;
  width: 50%;
  height: 25%;
  border: white 0.1rem solid;
}

.skill-name {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.upper-layer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--third);
  height: 100%;
  width: 100%;
}

.skill:hover {
  cursor: pointer;
}

.skill:hover .upper-layer {
  transition: all 1s ease-in-out;
  display: none;
}

.skill:hover .lower-layer {
  display: block;
}

.lower-layer {
  display: none;
  height: 100%;
  width: auto;
  padding: 1rem;
}

.skill-image {
  height: 50%;
}

.testimonial-wrapper {
  background-color: var(--secondary);
  /* color: var(--secondary); */
}

.testimonial {
  padding-inline: 10%;
  /* height: 100%; */
  padding-block: 5%;
}

.projects-wrapper {
  background-color: white;
  /* color: var(--secondary); */
}

.projects {
  padding-inline: 10%;
  /* height: 100%; */
  padding-block: 5%;
}

.projects-header {
  font-size: 2rem;
  font-weight: 600;
  margin-block: 0rem;
}

.projects-description {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 2.5rem;
  color: var(--grey);
}

.project-image {
  /* height: 50%; */
  width: 100%;
  object-fit: cover;
  position: relative;
}

.projects-details {
  position: absolute;
  bottom: 0;
  left: 0;
  /* background-color: var(--secondary); */
  /* opacity: 0.5; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 2rem;
  color: white;
}

.projects-details-header {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.projects-details-description {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  margin-bottom: 2rem;
  /* color: var(--grey); */
}

.contact-wrapper {
  background-color: var(--third);
  /* color: var(--secondary); */
}

.contact {
  padding-inline: 10%;
  /* height: 100%; */
  padding-block: 5%;
}

.contact-header {
  font-size: 2rem;
  font-weight: 600;
  margin-block: 0rem;
}

.contact-description {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 1.75rem;
  color: var(--grey);
}

.contact-input-label {
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.contact-input {
  min-width: 60%;
  height: 1.5rem;
  border: none;
  /* border-bottom: 1px solid var(--grey); */
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--grey);
  border-radius: 0.5rem;
  font-family: 'Poppins', sans-serif;
}

.contact-input:focus {
  outline: none;
}

.contact-textarea {
  min-width: 60%;
  border: none;
  /* border-bottom: 1px solid var(--grey); */
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--grey);
  border-radius: 0.5rem;
  font-family: 'Poppins', sans-serif;
}

.contact-textarea:focus {
  outline: none;
}

.contact-input-invalid {
  border: 1px solid red;
}

.contact-send-message-btn {
  text-decoration: none!important;
}

.contact-submit {
  margin-block: 1rem;
  background-color: var(--secondary);
  border: var(--primary) 1px solid;
  color: var(--primary);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: 400;
  cursor: pointer;
  width: fit-content;
  transition: all 0.3s ease-in-out;
}

.contact-submit:hover {
  background-color: var(--primary);
  color: var(--secondary);
}

.contact-additional {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0rem;
}

.contact-icon {
  height: 1.5rem;
  width: 1.5rem;
  font-size: 1rem;
  margin-inline: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 1230px) {
  .main-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 630px) {
  html {
    font-size: 12px;
  }

  .info {
    padding-inline: 1.5rem;
  }

  .info-header {
    margin-top: 0;;
  }

  .info-summary {
    width: 100%;
  }

  .info-links {
    margin-top: 5rem;
    /* margin-bottom: 8rem; */
  }

  .portfolio-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .about-me {
    padding-top: 20%;
  }

  .projects {
    padding-top: 20%;
  }

  .projects-details-header {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .projects-details-description {
    margin-bottom: 0.6rem;
  }

  .contact {
    padding-top: 20%;
    font-size: 16px!important;
  }
}
